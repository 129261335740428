.cma-active {
  background: lavender;
}

.socioscapes-ui {
  z-index: 1;
}

.vh-75 {
  min-height: 75vh !important;
}

.vh-50 {
  min-height: 50vh !important;
}